<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <p>Переходим к анатомии наших заготовок! Все будет очень просто )</p>
            <h3 class="nomargin">Необходимые материалы:</h3>           
            <ul>
                <li>Шкурки</li>
                <li>Следок (обычный женский)</li>
                <li>Макетный нож</li>
                <li>Силиконовые кости (скошенная и конусная)</li>
                <li>Шаблон для квилинговых форм</li>
                <li>Линейка с круглыми отверстиями</li>
                <li>Детская присыпка</li>
            </ul>
            <h3>1. Анатомия кисти</h3>
        </div>

        <VideoView video-id="d34a717ec52d43449048e34d386a7625"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Сначала все <b>шкурим</b>, убираем весь целлюлит шкуркой средней зернистости. Помним что: </li>
                <ul type='a' class='nomargin'>
                    <li>Ладонь мягкая, скругленная, без углов</li>
                    <li>Сверху пальчики плоские, снизу скругленные</li>
                    <li>Пальцы смотрят в одну сторону, прямо</li>
                </ul>
                <li>Потом <b>выглаживаем следком</b></li>
                <ul type='a' class='nomargin'>
                    <li>Сами пальчики. Чтобы они были не квадратными, а округлыми</li>
                    <li>Между пальцами. Но следите чтобы пальцы по ширине были одинаковыми. Также у основания пальцы толще, на конце тоньше.</li>
                </ul>
                <li><b>Дорабатываем объем</b> сверху на ладони</li>
                <li><b>Лепим 3 холма</b> на ладони, самый большой у большого пальца</li>
                <li>Используем синтетическую кисть и силиконовый стек, чтобы плавно разгладить объемы</li>
            </ol>

            <a @click="showImage('/files/BodoirV2/hand2.jpg', 'swal-image2')" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-hand2.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>
            
            <h3>2. Анатомия стопы</h3>
        </div>

        <VideoView video-id="d966948fd78746488b16bfd1da66ec99"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li><b>Шкурим жесткой шкуркой</b></li>
                <li><b>Отшкуриваем площадку носка</b>, чтобы она была параллельна полу. Также площадка пятки параллельна носку.</li>
                <li>Провьрете, что <b>обе стопы были симметричны</b>. Также прижмите носок обоих стоп к полу и проверьте, чтобы пятки поднялись на один уровень</li>
            </ol>

            <h3>3. Нога. Окончательная доработка</h3>
        </div>

        <VideoView video-id="8076315631bd4a34a4f6a155b36f4344"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li><b>Шкурим.</b>Сначала жесткой шкуркой, потом средней зернистости, потом финишной мелкой зернистости или на вспененной основе</li>
                <li><b>Дорабатываем по форме трафарета</b></li>
            </ol>

            <h3>4. Рука. Окончательная доработка</h3>
        </div>

        <VideoView video-id="8c206a1fb72e4f4c8259e717bc135c5c"/>

         <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li><b>Шкурим.</b> Сначала жесткой шкуркой, потом средней зернистости, потом финишной мелкой зернистости или на вспененной основе</li>
            </ol>

            <h3>5. Анатомия груди. Часть 2</h3>
        </div>

        <VideoView video-id="4eb28825a83d4a0ebcebf90f2d1d9ff4"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Выглаживаем жесткой шкуркой</li>
                <li><b>Намечаем ключицу:</b></li>
                <ul type='a' class='nomargin'>
                    <li>Проводим прямую линию от одного плеча до другого</li>
                    <li>Чуть ниже располагается яремная ямка</li>
                    <li>От нее рисуем S-образные линии к плечам</li>
                </ul>
                <li><b>Намечаем лопатки:</b></li>
                <ul type='a' class='nomargin'>
                    <li>Ширина лопатки — 1/3 спины</li>
                    <li>Высота лопатки по соскам</li>
                    <li>Лопатка имеет форму треугольника</li>
                    <li>Лопатка растет не прямо, а под небольшим углом</li>
                </ul>
                <li><b>Намечаем грудь:</b></li>
                <ul type='a' class='nomargin'>
                    <li>Соски располагаются на концах равностороннего треугольника (вершина — яремная ямка)</li>
                    <li>Грудь имеет каплевидную форму</li>
                </ul>
                <li>Не забудьте <b>убрать карандашную наметку</b> перед лепкой! Но так, чтобы вы могли разглядеть основные точки</li>
                <li><b>Лепим ключицы</b> (помним про гармонию щечек и костей)</li>
                <ul type='a' class='nomargin'>
                    <li>Формируем колбаску</li>
                    <li>Выкладываем ее в S образной форме</li>
                    <li>Убираем объем с плеча</li>
                    <li>Нижнюю часть ключицы плавно примазываем</li>
                    <li>Сверху ключица имеет небольшой выступ, как лесенка</li>
                    <li>Проверьте, чтобы ключицы были на одном уровне, одинаковым объемом, и под одинаковы наклоном</li>
                </ul>
                <li><b>Лепим грудь</b></li>
                <ul type='a' class='nomargin'>
                    <li>Формируем небольшую капельку</li>
                    <li>Располагаем под наклоном (от центра в бок)</li>
                    <li>Так как особа юная, под грудью нет складки</li>
                    <li>Весь объем груди собирается к центру, соску</li>
                </ul>
            </ol>

            <a @click="showImage('/files/BodoirV2/chest2.jpg', 'swal-image')" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-chest2.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>6. Основа под шарики. Часть 1</h3>
        </div>

        <VideoView video-id="020a34e551904512878340d5cb31f0a3"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Сколько нужно шариков:</b></p>
            <ul>
                <li><b>ВАЖНО!!!</b> Нужны <b>2 полусферы для глаз</b>. Размер 11-13мм </li>
                <li>4 шарика для кисти и стопы (8 полусфер)</li>
                <li>2 полусферы для локтя</li>
                <li>2 полусферы для колена</li>
                <li>1 полусфера для шеи</li>
            </ul>

            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Хорошо разминаем пластик</li>
                <li>Формируем толстую сосиску чуть меньше отверстия в квиллинговой форме</li>
                <li>Заполняем отверстие</li>
                <li>Убираем излишки пластика от центра к краям</li>
                <li>Если нужно, делаем углубление</li>
                <li>Оставялем сохнуть</li>
            </ol>

            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li><b>Пролепить кисть</b></li>
                <li><b>Доработать стопу</b> </li>
                <li><b>Доработать ногу и руку</b> </li>
                <li><b>Пролепить ключицу и грудь</b></li>
                <li><b>Сделать заготовки для шариков</b></li>
                <li><b>Подготовить материалы</b> к следующему занятию: стеки, циркуль, линейка </li>
            </ol>
            <h3><a href="/mycourses/homework">Как сдавать ДЗ куратору / в чат?</a></h3>
           <h3>Время выполнения: 4ч</h3>
        </div>
        <div class="swal-image" style="display:none"></div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage,
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>
    .swal-image {
        height: 80%;
        width: 70%;
    }

    .swal-image2 {
        height: 80%;
    }
</style>